import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
//import { PrivilegesService } from './privileges/privileges.service';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent
  },
  //{
  //  path: 'home',
  //  loadChildren: '../cwm/cwm.module#CWMModule'
  //},
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'Login',
    component: LoginComponent
  },
  {
    path: 'ForgetPassword',
    component: ForgotPasswordComponent
  },
  {
    path: 'ResetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'ResetPassword/:UserGUID',
    component: ResetPasswordComponent
  },
  {
    path: 'admin',
    canActivate: [AuthGuardService],
    loadChildren: '../admin/admin.module#AdminModule'
  },
  {
    path: 'developer',
    canActivate: [AuthGuardService],
    loadChildren: '../developer/developer.module#DeveloperModule'
  },
  {
    path: 'unsubscribe/:MessageType/:UserGuid',
    component: UnsubscribeComponent
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
