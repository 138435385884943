import { Component, ViewChild, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message } from 'primeng/components/common/api';
import { Location } from '@angular/common';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';

import { LoaderService } from './core/services/loader.service';
import { CookieService } from 'ngx-cookie-service';
import { BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment.prod';
//import { VersionCheckService } from './core/services/version-check.service';

@Component({
  providers: [CookieService],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  UserInfo: UserInfoObject;
  UserLoggedIn: boolean = false;

  public loading: boolean;
  PageName: string = '';
  msgs: Message[] = [];
  @ViewChild('childModal') childModal: ModalDirective;

  scrHeight: any;
  scrWidth: any;

  loadingRouteConfig: boolean;


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
  }

  constructor(private router: Router, public loaderService: LoaderService, private modalService: BsModalService,
    private cookieService: CookieService, private locationVar: Location) {
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    });
    this.getScreenSize();

    var asyncLoadCount = 0;

    router.events.subscribe(
      (event: RouterEvent): void => {
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;

        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
        }
        this.loadingRouteConfig = !!asyncLoadCount;
      }
    );
  }

  ngOnInit() {
    this.UserLoggedIn = false;
    this.validateUser();
    this.router.events.subscribe(event => {
        if (event instanceof RouteConfigLoadStart) {
            this.loadingRouteConfig = true;
        } else if (event instanceof RouteConfigLoadEnd) {
            this.loadingRouteConfig = false;
        }
    });
      //this.versionCheckService.initVersionCheck(environment.versionCheckURL, environment.versionCheckURL2)
  }

  public validateUser() {
    if (!localStorage.getItem("UserInfo") || JSON.parse(localStorage.getItem("UserInfo")).Login == null || JSON.parse(localStorage.getItem("UserInfo")).Login == undefined) {
      var redirectionUrl = "";
      console.log("account in valid");
    }
    else {
      this.UserInfo = JSON.parse(localStorage.getItem("UserInfo")).Login;
      this.UserLoggedIn = true;
    }
    console.log("this.UserInfo", this.UserInfo)
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      window.scrollTo(0, 0);
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }
  }

  public setMessage(severity_value: any, summary_value: any, message_value: any) {
    this.msgs.push({ severity: severity_value, summary: summary_value, detail: message_value });
  }

  //config = {
  //  keyboard: false,
  //  ignoreBackdropClick: true
  //};

  public userRejectionRedirect(PageName) {
    this.PageName = PageName;
    //this.router.navigate(['/admin', 'unauthorized-access']);
  }

  public LogoutUser() {
    this.cookieService.deleteAll();
    this.cookieService.delete('authorizetoken111', '/');

    localStorage.clear();
    this.locationVar.replaceState('/'); // clears browser history so they can't navigate with back button
    window.location.href = "/";
    //this.router.navigate(['/']);
  }

}


export class UserInfoObject {
  UserName: string;
  FirstName: string;
  DefaultBusinessTypeID: number;
  TypeId: number;
  UserID: number;
  RoleID: number;
  FranchiseID: number;
  RoleName: string;
  FranchiseTypeID: number;
}
