﻿import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from "../services/common.service";
import { BackEndUrlService } from '../services/backend.url.service';
import { HttpClientService } from '../services/http.client.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Title } from '@angular/platform-browser';
import { Message } from 'primeng/components/common/api';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { AppComponent } from "../../app.component";

@Component({
    providers: [CommonService, BackEndUrlService, HttpClientService],
    templateUrl: "./unsubscribe.component.html",
    styleUrls: [
        './unsubscribe.component.scss',
        '../../../assets/common/css/primeng/primeicons.css',
        '../../../assets/common/css/primeng/primeng.css',
        '../../../assets/common/css/primeng/theme.css',
        '../../../assets/common/css/primeng/custom-primeng.css'
    ]
})

export class UnsubscribeComponent implements OnInit {
    private sub: any;
    msgs: Message[] = [];
    dataAvailable: boolean = false;
    showError: boolean = false;
    showSuccess: boolean = false;
    redirectUrl: string = "";
    ErrorMessage: string = "";
    UserGuid: string = "";
    Message: string = "";
    MessageType: string = "";
    MessageTypeID: number = 0;
    EmployeeDetails: any = {};
    date = new Date();
    CurrentYear = this.date.getFullYear();


    constructor(private formBuilder: FormBuilder, public Common: CommonService, private cookieService: CookieService,
        private titleService: Title, private route: ActivatedRoute, private router: Router, public _main: AppComponent, private _backEndUrlService: BackEndUrlService, @Inject(DOCUMENT) document, private http: HttpClientService) {
        this.cookieService.deleteAll();
    }

    apiUrl: string = this._backEndUrlService.backendUrl;

    ngOnInit() {
        this.titleService.setTitle('Unsubscribe | SubSource');

        this.sub = this.route.params.subscribe(params => {
            this.UserGuid = params['UserGuid'];
            this.MessageType = params['MessageType'];
        });

        if (this.UserGuid && this.UserGuid != null && this.UserGuid != '') {
            this.FetchUnsubscribedEmailEmployeeDetails();
        }

        if (this.MessageType == 'pmsgeb') {
            this.Message = "email broadcast"
            this.MessageTypeID = 1;
        }

        else if (this.MessageType == 'pmsgsh') {
            this.Message = "social hiring"
            this.MessageTypeID = 2;
        }

        else if (this.MessageType == 'pmsgeeng') {
            this.Message = "employee engagement"
            this.MessageTypeID = 3;
        }

        else if (this.MessageType == 'pmsgshal') {
            this.Message = "new job notifications"
            this.MessageTypeID = 7;
        }

        console.log(this.UserGuid)
        console.log(this.MessageTypeID)
    }


    public setMessage(severity_value: any, summary_value: any, message_value: any) {
        this.msgs.push({ severity: severity_value, summary: summary_value, detail: message_value });
    }

    //************************** Unsubscribe Email****************************//
    UnsubscribeEmail() {
        var data = "UserGuid=" + this.UserGuid + "&MessageTypeID=" + this.MessageTypeID;
        console.log(data)
        var observable = this.http.getwithparam("SaveUnsubscribedEmail/", data);
        observable.subscribe(
            (res: any) => {
                console.log(res);
                if (res.Status > 0) {
                    console.log(res);
                    this._main.setMessage('success', 'Success Message', res.Message)
                    this.router.navigate(['/home']);
                    //this.showSuccess = true;
                    //this.showError = false;
                    //this.Message = res.Message;
                }
                else {
                    this._main.setMessage('error', 'Error Message', res.Message)
                    //this.showError = true;
                    //this.showSuccess = false;
                    //this.ErrorMessage = res.Message;
                }
            },
            err => {
                this._main.setMessage('error', 'Error Message', err)
                //this.showError = true;
                //this.showSuccess = false;
                //this.ErrorMessage = err;
            }
        );
    }


    //************************** Employee Details****************************//
    FetchUnsubscribedEmailEmployeeDetails() {
        var data = "UserGuid=" + this.UserGuid;
        console.log(data)
        var observable = this.http.getwithparam("GetUnsubscribedEmailEmployeeDetails/", data);
        observable.subscribe(
            (res: any) => {
                if (res.Status > 0) {
                    this.EmployeeDetails = res.Data;
                    //this._main.setMessage('success', 'Success Message', res.Message)
                }
                else {
                    //this._main.setMessage('error', 'Error Message', res.Message)
                }
            },
            err => {
                //this._main.setMessage('error', 'Error Message', err)
            }
        );
    }
}
