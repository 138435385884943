import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthenticationService {
  //token = {
  //    refresh_token: 'refreshtokencode',
  //    exp: new Date((new Date().getDate() + 1)),
  //    access_token: {
  //        username: 'user',
  //        roles: ['Admin', 'RegisteredUser', 'Super User']
  //    }
  //};

  //tokenKey: string = "a6smm_utoken"

  constructor(private router: Router, private cookieService: CookieService) {
  }

  //login(username, password) {
  //  this.setToken(this.token);
  //  console.log("set token")
  //  this.router.navigate(['admin', 'dashboard']);
  //}

  //logout() {
  //  this.removeToken();
  //  console.log("remove token")
  //  this.router.navigate(['login']);
  //}

  //getToken() {
  //    this.setToken(this.token);
  //    return JSON.parse(localStorage.getItem(this.tokenKey));
  //}

  //setToken(token) {
  //    localStorage.setItem(this.tokenKey, JSON.stringify(token));
  //}

  getAccessToken() {
    return this.cookieService.get("authorizetoken111");
  }

  isAuthenticated() {
    ////let token = localStorage.getItem(this.tokenKey);
    //var accesedPath = window.location.pathname;
    ////let userType = localStorage.getItem("UserType");
    //let userType = "account";
    //console.log(accesedPath)
    ////console.log(userType)
    //if (accesedPath == "/login" || accesedPath == "/Login") {
    //  return true;
    //}
    //else if (accesedPath.includes(userType)) {
    //  return true;
    //}
    //else {
    //  return false;
    //}
    return true;
  }

  //refreshToken() {
  //    this.token.exp = new Date((new Date().getDate() + 1));
  //    this.setToken(this.token);
  //}

  //removeToken() {
  //    localStorage.removeItem(this.tokenKey);
  //}

}
