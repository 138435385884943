﻿import { Injectable, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientService } from "../services/http.client.service";
import { CommonService } from "../services/common.service";
import { UserPrivileges } from "./privileges.model";

import { Observable } from "rxjs/Rx";
import { AppComponent } from "../../app.component";

@Injectable()

//@Component({
//  providers: [HttpClientService]
//})

export class PrivilegesService {

    UserPrivileges: UserPrivileges = new UserPrivileges();

    constructor(private router: Router, private route: ActivatedRoute, private http: HttpClientService, private common: CommonService,
        public _main: AppComponent) {
    }

    public GetPrivilegesDetails(currentPage) {
        var BusinessTypeId = this.common.BusinessTypeService().get();
        //var url = "";
        //console.log(currentPage)
        //if (currentPage || currentPage != "") {
        //  url = currentPage;
        //}
        //else {
        //  var routeUrl = this.router.url;
        //  var arrUrl = routeUrl.split("/");
        //  url = arrUrl[2];
        //}
        var url = "Company";
        var data = "BusinessTypeId=" + BusinessTypeId + "&FormGroupUrl=" + url;
        //var res = {
        //  Status: 1,
        //  Data: { IsView: true, IsEdit: true, IsDelete: true }
        //}
        //return res;
        return this.http.getwithparam("GetPrivilegesReturn/", data);
    }

    GetPagePrivileges(currentPage) {
        var BusinessTypeId = this.common.BusinessTypeService().get();
        var url = "";
        if (currentPage || currentPage != "") {
            url = currentPage;
        }
        else {
            var routeUrl = this.router.url;
            var arrUrl = routeUrl.split("/");
            url = arrUrl[(arrUrl.length - 1)];
        }

        this.UserPrivileges.IsAdd = true;
        this.UserPrivileges.IsView = true;
        this.UserPrivileges.IsEdit = true;
        this.UserPrivileges.IsDelete = true;

        //var data = "BusinessTypeId=" + BusinessTypeId + "&FormGroupUrl=" + url;
        //console.log("PRIVILEGE DATA ", data);
        //var observable = this.http.getwithparam("GetPrivilegesReturn/", data);
        //observable.subscribe(
        //    (res: any) => {
        //        if (res.Status > 0) {
        //            this.UserPrivileges = res.Data;
        //            console.log(this.UserPrivileges)
        //            if (this.UserPrivileges && this.UserPrivileges.IsView) {
        //                //Success
        //            }
        //            else {
        //                this._main.userRejectionRedirect(url);
        //            }
        //        }
        //        else {
        //            this._main.userRejectionRedirect(url);
        //        }
        //    },
        //    err => {
        //        this._main.userRejectionRedirect(url);
        //    }
        //);

        const privilegeObservable = new Observable(observer => {
            setTimeout(() => {
                observer.next(this.UserPrivileges);
            }, 500);//1000
        });

        return privilegeObservable;
        //return this.UserPrivilegesObject;
    }

}
