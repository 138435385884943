﻿import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';

//Loading Bar
import { LoadingModule } from 'ngx-loading';

//Prime NG Modules
import { GrowlModule } from 'primeng/growl';

import { GlobalErrorHandlerService } from './core/services/global-error-handler.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

//const RxJS_Services = [HTTPListener, HTTPStatus];
import { LoaderService } from './core/services/loader.service';
import { LoaderInterceptor } from './core/services/api-interceptor.service';

import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule, BrowserAnimationsModule, CommonModule, FormsModule, ReactiveFormsModule,
      CoreModule, HttpClientModule, LoadingModule,
        GrowlModule, ModalModule.forRoot()
    ],
    bootstrap: [AppComponent],
    providers: [
        GlobalErrorHandlerService,
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        }
    ]
})

export class AppModule { }
