import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from '@angular/router';

import { CookieService } from "ngx-cookie-service";
import { AppComponent } from "../../app.component";

import { BackEndUrlService } from '../../core/services/backend.url.service';

import { HttpHeaders, HttpClient } from "@angular/common/http";

@Component({
  providers: [CookieService, BackEndUrlService],
  templateUrl: "./reset-password.component.html",
  styleUrls: ['../login/login.component.scss', './reset-password.component.scss', '../../shared/change-password/change-password.component.scss']
})

export class ResetPasswordComponent implements OnInit {
  private sub: any;
  UserGUID: string = "";
  PasswordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}/;

  IsvalidRequest: boolean = false;
  IsLoadingComplete: boolean = false;
  showError: boolean = false;
  showSuccess: boolean = false;
  Message: string = "";
  ResetPasswordForm: FormGroup;
  date = new Date();
  CurrentYear = this.date.getFullYear();
  passwordPattern = /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!,@,#,$,%,^,&,*]))/;

  constructor(private formBuilder: FormBuilder, private http: Http, private titleService: Title,
    private cookieService: CookieService, private route: ActivatedRoute, private router: Router, public _main: AppComponent,
    private backEndUrlService: BackEndUrlService, private httpCl: HttpClient) {
    this.cookieService.deleteAll();
  }

  apiUrl: string = this.backEndUrlService.backendUrl;

  ngOnInit() {
    this.titleService.setTitle('Reset Password | SubSource');

    this.sub = this.route.params.subscribe(params => {
      this.UserGUID = params['UserGUID'];
    });

    if (this.UserGUID != null && this.UserGUID != "" && this.UserGUID != undefined) {
      this.ValidateUser();
    }
    else {
      this.IsvalidRequest = false;
    }

    this.ResetPasswordForm = this.formBuilder.group({
      Passkey: ['', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordPattern)]],
      ConfirmPasskey: ['', Validators.required]
    });
  }


  //console.log("response", JSON.stringify(res))

  ValidateUser() {
    this.showError = false;
    this.showSuccess = false;
    let _headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    var data = "UserAccountGuid=" + this.UserGUID;
    let options = {
      headers: _headers
    };
    var url = this.apiUrl + "IsResetPasswordLinkExpired/?";
    console.log("data", data);
    var observable = this.httpCl.get(url + data, options);
    observable.subscribe(
      (res: any) => {
        console.log("response", JSON.stringify(res))
        if (res.Status == 1) {
          this.IsvalidRequest = true;
        }
        else {
          this.IsvalidRequest = false;
        }
        this.IsLoadingComplete = true;
      },
      err => {
        console.log("response err", JSON.stringify(err))
        this.IsLoadingComplete = true;
        this.IsvalidRequest = false;
      }
    );

    //var observable = this.http.post(url, data, options);
    //observable.subscribe(res => this.ValidateSuccess(res), res => this.ValidateError(res));
  }
  //ValidateError(err) {
  //  this.IsLoadingComplete = true;
  //  this.IsvalidRequest = false;

  //}
  //ValidateSuccess(res) {
  //  if (res.json().Status == 1) {
  //    this.IsvalidRequest = true;
  //  }
  //  else {
  //    this.IsvalidRequest = false;
  //  }
  //  this.IsLoadingComplete = true;
  //}
  //*******[START]*********************** Login **************************************//

  ResetPassword() {
    this.showError = false;
    this.showSuccess = false;
    if (this.ResetPasswordForm.valid) {

      if (this.ResetPasswordForm.value.Passkey != this.ResetPasswordForm.value.ConfirmPasskey && this.ResetPasswordForm.value.Passkey.length != this.ResetPasswordForm.value.ConfirmPasskey.length) {
        this.showError = true;
        this.Message = "Password and Confirm password don't match";
      }
      else {
        let _headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

        var regExp = /&/gi;
        var strPassword = this.ResetPasswordForm.value.Passkey;
        var newPassword = "";

        if (strPassword.search(regExp) == -1) {
          newPassword = this.ResetPasswordForm.value.Passkey;

        } else {
          newPassword = strPassword.replace(regExp, "%26");
        }

        var data = "grant_type=password&Passkey=" + newPassword + "&UserAccountGuid=" + this.UserGUID;
        let options = {
           headers: _headers
        };
        var url = this.apiUrl + "ResetPassword/";
        var observable = this.httpCl.post(url, data, options);
        observable.subscribe(
          (res: any) => {
            if (res.Status == 1) {
              this.showSuccess = true;
              this.Message = res.Message;
              this.ResetPasswordForm.reset();
            }
            else {
              this.showError = true;
              this.Message = res.Message;
              this.ResetPasswordForm.reset();
            }

          },
          err => {
            this.showError = true;
            this.Message = err.json();
            this.ResetPasswordForm.reset();

          });
      }
    } else {
      this.showError = true;
    }
  }
  //*******[END]*********************** Login **************************************//
}

