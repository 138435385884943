﻿import { Injectable } from '@angular/core';

@Injectable()

export class DownloadService {

  downloadPath: string = '';
  pendingDownloadFile = "";
  downloadFile(downloadPath: string) {
    if (downloadPath != "") {
      var element = document.createElement('a');
      element.setAttribute('href', downloadPath);
      if ((downloadPath.indexOf('.xlsx') !== -1) || (downloadPath.indexOf('.xls') !== -1)) {
      } else {
          element.setAttribute('target', "_blank");
      }
     
      element.innerHTML = "";
      //var newWin = window.open(downloadPath);

      //if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      //    alert("Please allow popups and refresh the page to download files. Thank You!");
      //    return false;
      //}
      //else {
      //    
      //}
      element.click();

      element.remove();
      return true;
    }
    else {
      return false;
    }
  }
}
