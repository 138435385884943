//import { Injectable } from '@angular/core';
//import { HttpHeaders, HttpClient } from '@angular/common/http';
//import { Subject } from 'rxjs';
//import { window } from 'rxjs/operators';

//function _window() : any {
//  return window;
//}


//@Injectable({
//  providedIn: 'root'
//})

//export class AuthService {



//  constructor() { }

//  get nativeWindow(): any {
//    console.log('d')
//    return _window();
//    console.log('r')
//  }

//}


import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

function _window(): any {
  // return the global native browser window object
  return window;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(@Inject(PLATFORM_ID) private platformId: object) { }

  get nativeWindow(): any {
    if (isPlatformBrowser(this.platformId)) {
      return _window();
    }
  }
}
