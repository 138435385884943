import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpModule } from '@angular/http';
import { CookieService } from 'ngx-cookie-service';

import { CoreRoutingModule } from './core-routing.module';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { RouterModule } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { AuthGuardService } from './services/auth-guard.service';

import { HttpClientService } from './services/http.client.service'
import { PrivilegesService } from './privileges/privileges.service';
import { BackEndUrlService } from './services/backend.url.service';
import { SampleFilesService } from './sample-files/sample-files.service';
import { AuthService } from './services/auth.service';

import { ForgotPasswordComponent } from './forget-password/forget-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

//Prime NG Modules
import { GrowlModule } from 'primeng/growl';

import { DeviceDetectorModule } from 'ngx-device-detector';

@NgModule({
  imports: [
    CommonModule, CoreRoutingModule, ReactiveFormsModule, FormsModule, HttpModule, GrowlModule, DeviceDetectorModule.forRoot()
  ],
  declarations: [LoginComponent, NotFoundComponent, ForgotPasswordComponent, ResetPasswordComponent, UnsubscribeComponent],
  exports: [
    RouterModule
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    CookieService,
    HttpClientService,
    PrivilegesService,
    BackEndUrlService,
    SampleFilesService,
    AuthService,
  ]
})
export class CoreModule { }
