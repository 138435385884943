import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";

import { BackEndUrlService } from '../services/backend.url.service';
import { Http, Response, Headers, RequestOptions } from "@angular/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { CookieService } from "ngx-cookie-service";
import { AppComponent } from "../../app.component";

import { HttpHeaders, HttpClient } from "@angular/common/http";

@Component({
  providers: [CookieService, BackEndUrlService],
  templateUrl: "./forget-password.component.html",
  styleUrls: ['../login/login.component.scss']
})

export class ForgotPasswordComponent implements OnInit {
  returnUrl: string;
  showError: boolean = false;
  showSuccess: boolean = false;
  Message: string = "";
  ForgetForm: FormGroup;
  date = new Date();
  CurrentYear = this.date.getFullYear();
  apiUrl: string = "";

  IsloginUserType: boolean = false;
  SelectedUserType: any;
  loginUserTypeID: any = -1;
  ShowUserType: number = 0;

  constructor(private formBuilder: FormBuilder, private httpCl: HttpClient, private titleService: Title, private cookieService: CookieService, public _main: AppComponent, private _backEndUrlService: BackEndUrlService) {
    this.cookieService.deleteAll();

    this.apiUrl = this._backEndUrlService.backendUrl;
  }

  ngOnInit() {
    this.titleService.setTitle('Forgot Password | SubSource');
    this.ForgetForm = this.formBuilder.group({
      UserName: ['', Validators.required],
      PassMail: ['']
    });
  }

  //*******[START]********************************************************** Reset ****************************************************************************

  Submitted = false;
  SelectedEmail = "";
  ResetPassword() {
    this.showError = false;
    this.showSuccess = false;
    this.Submitted = true;
    if ((this.IsloginUserType && this.loginUserTypeID > 0) || !this.IsloginUserType) {
      if (this.ForgetForm.valid) {
        let _headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        this.SelectedEmail = this.ForgetForm.value.PassMail;
        var data = "grant_type=password&PassMail=" + this.ForgetForm.value.PassMail + "&UserName=" + this.ForgetForm.value.UserName
          + "&TypeID=" + this.loginUserTypeID;
        let options = {
          headers: _headers
        };
        console.log('data', data);
        var url = this.apiUrl + "ForgotPassword/";
        var observable = this.httpCl.post(url, data, options);
        console.log('observable', observable);
        observable.subscribe((res: any) => {
          console.log('res', res);
          if (res.Status == 1) {
            this.IsloginUserType = false;
            this.showSuccess = true;
            this.ForgetForm.reset();
            this.Message = res.Message;
          }
          else if (res.Status == -2 || res.Status == -3) {
            this.ShowUserType = res.Status;
            this.IsloginUserType = true;
          }
          else {
            this.showError = true;
            this.Message = res.Message;
            this.Submitted = false;
            this.IsloginUserType = false;
          }
        },
          (err: any) => {
            this.showError = true;
            this.Message = err.error.error_description;
            this.Submitted = false;
          }
        );
      }
      else {
        this.showError = true;
        this.Message = "Please enter username to continue."
      }
    }
    else {
      this.showError = true;
      this.Message = "Please select account type."
    }
  }
  //*******[END]********************************************************** Reset ****************************************************************************

  onLoginTypeChange(val: any) {
    if (val > 0) {
      this.loginUserTypeID = val;
    }
    else {
      this.loginUserTypeID = -1;
    }
  }


}
