﻿import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClientService } from "../services/http.client.service";
import { BackEndUrlService } from "../services/backend.url.service";
import { CommonService } from '../services/common.service';
import { DownloadService } from '../services/download.service';

@Injectable()

//@Component({
//    providers: [BackEndUrlService]
//})

export class SampleFilesService {

  FileUrl: string = this._backEndUrlService.backendUrl + "Files/SampleFile/";

  constructor(private _backEndUrlService: BackEndUrlService, private download: DownloadService) {
  }

  ReturnSampleFiles(pageName: string) {
    var selectedFile = "";
    if (pageName == "company-import") {
      selectedFile = this.FileUrl + "Company Sample File.xlsx";
    }
    else if (pageName == "shop-import") {
      selectedFile = this.FileUrl + "Store Sample File.xlsx";
    }
    else if (pageName == "labor-productivity-import") {
      selectedFile = this.FileUrl + "Labor Productivity Sample File.xlsx";
    }
    else if (pageName == "weekly-budget-hours-import") {
      selectedFile = this.FileUrl + "Weekly Budget Hours Sample File.xlsx";
    }
    else if (pageName == "free-trial-shop-import") {
      selectedFile = this.FileUrl + "Free Trial Stores Sample File.xlsx";
    }
    else if (pageName == "employee-file-view-import") {
      selectedFile = this.FileUrl + "Employee Sample File.xlsx";
    }
    else if (pageName == "label-values-import") {
      selectedFile = this.FileUrl + "Label Values Sample File.xlsx";
    }
    else if (pageName == "hire-new-employee-bgc-import") {
      selectedFile = this.FileUrl + "Hire Employee Sample File BGC.xlsx";
    }
    else if (pageName == "hire-new-employee-everify-import") {
      selectedFile = this.FileUrl + "Hire Employee Sample File Everify.xlsx";
    }
    else if (pageName == "hire-new-employee-both-import") {
      selectedFile = this.FileUrl + "Hire Employee Sample File Both.xlsx";
    }
    else if (pageName == "hire-new-employee-import") {
      selectedFile = this.FileUrl + "Hire Employee Sample File.xlsx";
    }
    this.download.downloadFile(selectedFile);
  }

}
